$red: #fe2f4e;
$blue: #118df0;
$font-family: "Open Sans", sans-serif;

h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  color: #2a2c35 !important;
}

.card-sections {
  max-width: 320px;
  border-radius: 8px !important;
  border: 2px solid #e7e9ee !important;

  transition: 0.2s ease;
}

.card-sections:hover {
  cursor: pointer;
  border: 2px solid #118df0 !important;
}

.card-sections h3 {
  font-family: $font-family;
  color: #2a2c35 !important;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.card-sections p {
  font-family: $font-family;
  color: #9799a2 !important;
}
