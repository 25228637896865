h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 700 !important;
  font-size: 18px;
  letter-spacing: 0.03em;
}

hr {
  border-top: 1px solid #aaa !important;
}

.text-question {
  font-family: 'Poppins', 'sans-serif';

  color: blue !important;
}

.text-question:hover {
  cursor: pointer;
}

.btn-outline-primary {
  font-family: "Poppins", sans-serif;
}

.btn-outline-primary:hover {
  font-family: 'Poppins', 'sans-serif';

  color: #fff !important;
}

.text-answer {
  font-family: 'Poppins', 'sans-serif';
}

.blink{
  font-family: 'Poppins', 'sans-serif';

  color: #118df0 !important;
}

.breadcrumb-item{
  font-family: 'Poppins', 'sans-serif';

  a{
    color: #118df0 !important;
  }
  
}

.related{
  font-family: 'Poppins', 'sans-serif';
  border: 0px !important;
  background-color: white !important;
  color: #118df0 !important;
  text-align: left !important;
  border: 0px !important;
  box-shadow: none !important;

}

.related:hover{
  text-decoration: underline !important;
}
