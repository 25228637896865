$red: #fe2f4e;
$blue: #118df0;
$darkBlue: #020244;
$font-family: "Poppins", sans-serif;

.nav-link {
  font-family: $font-family !important;
  font-weight: 500 !important;
  color: #555555 !important;
}

.navbar {
  background-color: white !important;
}

.footer-container {
  // background-color: #171717;
  background-color: $darkBlue;
  // max-height: 17rem;

}

a {
  text-decoration: none !important;
  color: white !important;
}

.copyright-text {
  color: #c7c7c7a8;
}

.suggestion{
  font-family:"Poppins", "sans-serif" !important;
  display: block !important;
  background-color: white !important ;
  color: black !important;
  border: 0px !important;
  text-align: left !important;
  width: 100% !important;
  padding-bottom: 10px !important;
  margin-bottom: 4px !important;
}
.suggestion:hover{
  color: $blue !important;
}
