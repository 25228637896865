$red: #fe2f4e;
$blue: #118df0;
$font-family: "Open Sans", sans-serif;
$poppins: "Poppins", sans-serif;

.search-bar-container {
  min-height: 35vh;
  background: linear-gradient(45deg, #00d2ff 0%, $blue 50%, #3a47d5 100%);
  position: relative;
  background-size: 400% 400%;
  animation: change 10s ease-in-out infinite;
}

@keyframes change {
  0%{
    background-position: 0 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position:0 50%;
  }
}
.search-title {
  font-family: $poppins;
  font-weight: 600 !important;
  color: #ffffff;
}

.input-group-text {
  background-color: #ffffff !important;
  border: none !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  color: #ced4da !important;
}

.form-control,
.form-control:focus {
  border-radius: 20px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 3em;
  font-family: "Open Sans", sans-serif;
  border: none !important;
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}
