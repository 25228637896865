h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 700 !important;
  font-size: 18px;
  letter-spacing: 0.03em;
}

hr {
  border-top: 1px solid #aaa !important;
}

.text-question {
  color: blue !important;
}

.text-question:hover {
  cursor: pointer;
}

.btn-outline-primary {
  font-family: "Poppins", sans-serif;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

.text-answer {
  font-family: "Poppins", sans-serif;
}
